<template>
  <div>
    <!--begin::Body-->
    <div class="kt-login__body">
      <Prompt></Prompt>
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>{{ $t('login.title') }}</h3>
          <div class="text-center">
            <b-spinner
              variant="primary"
              type="grow"
              label="Spinning"
              class="mr-3"
            ></b-spinner>
            <b-spinner
              variant="primary"
              type="grow"
              label="Spinning"
              class="mr-3"
            ></b-spinner>
            <b-spinner
              variant="primary"
              type="grow"
              label="Spinning"
              class="mr-3"
            ></b-spinner>
            <b-spinner
              variant="primary"
              type="grow"
              label="Spinning"
              class="mr-3"
            ></b-spinner>
          </div>
        </div>
        <!--begin::Form-->
        <!--end::Form-->
        <div class="kt-login__divider">
          <div class="kt-divider">
            <span></span>
          </div>
        </div>
        <div class="kt-login__version text-center">
          <p>{{ $t('common.system_version', { version: version }) }}</p>
        </div>
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import { mapState } from 'vuex';
import { Base64 } from 'js-base64';
import Qs from 'qs';
import { LOGIN_FROM_SIGN, VERIFY_AUTH } from '@/store/modules/auth/actions';
import Prompt from '@/components/Prompt/Index.vue';
export default {
  name: 'login-from-sign',
  data() {
    return {
      version: require('@/../package.json').version //用于显示版本号
    };
  },
  components: { Prompt },
  created() {
    // 通过sign登录相关操作
    let urlParams = new URLSearchParams(location.search);
    let base64secret = urlParams.get('secret');
    let secret = Qs.parse(Base64.decode(base64secret));
    this.$store.dispatch(LOGIN_FROM_SIGN, secret).then(resData => {
      this.$store
        .dispatch(VERIFY_AUTH, {
          path: '/',
          targetTecrmGroupId: resData.target
        })
        .then(verifyResData => {
          this.$router.push({ path: verifyResData.target_page });
        });
    });
    // 清除高级搜索的的缓存
    localStorage.removeItem('cache');
  },
  methods: {},
  computed: {
    ...mapState({
      error: state => state.auth.error
    })
  }
};
</script>
